import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import './escapades-post.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt, faHeart } from '@fortawesome/free-solid-svg-icons'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

const VoyagesPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <section className="voyages_post width_1200">
        <article
          itemScope
          itemType="http://schema.org/Article"
        >
          <div className="voyages_gallery_images">
            <div className="gallery_images" style={{background: `url(${post.frontmatter.featuredimage})`}}>
              {post.frontmatter.complet && <div className="background_gradient_complet"><span>Complet</span></div>}
              {post.frontmatter.promo && <div className="promo">- {post.frontmatter.pourcentpromo} %</div>}
              {post.frontmatter.coeur && <FontAwesomeIcon icon={faHeart} className="coeur" />}
            </div>
          </div>

          <div className="descriptions_voyage">
            <div className="width_45">
              <h2 className="pays">{post.frontmatter.pays}</h2>
                <div className="separator_left"></div>
              <h3 itemProp="headline">{post.frontmatter.title}</h3>
              <p className="tarif">Tarif : à partir de <span className="prix">{post.frontmatter.tarif}</span> TTC</p>

              <div className="contactez_nous">
                <p>Ce voyage vous intéresse ?</p>
                <Link to="/contact">Contactez-nous</Link>
              </div>
            </div>
            
            <div className="width_45">
              <h2>Description du voyage</h2>
                <div className="separator_left"></div>
              <div className="dates_voyage">
                <div className="date_voyage">
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  <h3>Date du voyage</h3>
                  <p>{post.frontmatter.datevoyage}</p>
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
              />
            </div>
          </div>
        </article>
      </section>
    </Layout>
  )
}

export default VoyagesPostTemplate

export const pageQuery = graphql`
  query VoyagesPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 10000)
      html
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        description
        datevoyage
        pays
        featuredimage
        tarif
        promo
        pourcentpromo
        coeur
        complet
      }
    }
  }
`